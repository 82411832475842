import Axios from "axios";

// const access_token = localStorage.getItem('access-token');

export const BASE_URL = "https://prod.publico-app.com";

const axios = Axios.create({
  baseURL: "https://prod.publico-app.com",
  timeout: 5000,
  // headers: {'X-Custom-Header': 'foobar'}
  // headers: {
  //   Authorization: 'Bearer ' + access_token,
  // },
});

export default axios;
