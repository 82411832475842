import { phoneVerificationServices } from "../services/phoneVerification.services";

export const register = async (name, email, session_id, phone) => {
  try {
    await phoneVerificationServices.register(name, email, session_id, phone);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
