import axios from "../axios";

const register = (name, email, session_id, phone) =>
  axios.post(`/v1/link/${session_id}/register`, {
    name: name,
    email: email,
    phone: `+972${phone}`,
  });

export const phoneVerificationServices = {
  register,
};
