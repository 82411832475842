import React from "react";
import {
  Routes,
  BrowserRouter as Router,
  Route,
  Navigate,
} from "react-router-dom";
import { SuccessPage } from "./components";

import PhoneVerificationRegister from "./components/PhoneVerificationRegister";
import { ROUTES } from "./constants/routes.constants";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path={ROUTES.PHONE_VERIFICATION_LINK_REGISTER}
          element={<PhoneVerificationRegister />}
        />
        <Route path={ROUTES.SUCCESS} element={<SuccessPage />} />
        <Route
          path={"*"}
          element={<Navigate to={ROUTES.PHONE_VERIFICATION_LINK_REGISTER} />}
        />
      </Routes>
    </Router>
  );
};
export default App;
