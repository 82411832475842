import React from "react";

import styles from "./phoneVerification/phoneVerification.module.scss";

interface Props {
  link?: string;
  data?: object;
}

const SuccessPage: React.FC<Props> = ({ link, data }) => {
  return (
    <div className={styles.phoneVerificationContainer}>
      <div className={styles.phoneVerification}>
        <p style={{ fontSize: "22px", lineHeight: "30px" }}>נרשמת בהצלחה!!</p>
      </div>
    </div>
  );
};

export default SuccessPage;
