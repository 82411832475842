import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { register } from "../actions/phoneVerification.action";
import { ROUTES } from "../constants/routes.constants";
import { validateEmail } from "../helpers/generalHelpers";
import Button from "./button/Button";
import Input from "./input/Input";

import styles from "./phoneVerification/phoneVerification.module.scss";

interface Props {
  link?: string;
  data?: object;
}

const PhoneVerificationRegister: React.FC<Props> = ({ link, data }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [isLoading1, setIsLoading1] = useState(false);

  const [searchParam] = useSearchParams();

  const navigate = useNavigate();

  const handleChange = (value: string) => {
    setError("");
    setError1("");
    setEmail(value);
  };

  const handlebtnClick = async () => {
    if (isEmpty(name)) {
      setError1("invalid name");
      return;
    }

    const err = validateEmail(email);
    if (!isEmpty(err)) {
      setError(err);
      return;
    }

    const ssd = searchParam.get("ssd");
    if (isEmpty(ssd)) {
      alert("Invalid SessionId");
      return;
    }

    const mobileNumberWithoutZero = phone.replace(/^0+/, "");
    if (mobileNumberWithoutZero.length < 8) {
      alert("Invalid mobile number");
      return;
    }

    setIsLoading1(true);
    const isSuccess = await register(name, email, ssd, mobileNumberWithoutZero);

    setIsLoading1(false);
    if (isSuccess) {
      navigate(ROUTES.SUCCESS);
    } else {
      alert("Something went wrong");
    }
  };

  return (
    <div className={styles.phoneVerificationContainer}>
      <div className={styles.phoneVerification}>
        <div style={{ marginBottom: "10px", height: "70px" }}>
          <Input
            placeholder="שֵׁם"
            value={name}
            setValue={setName}
            className={styles.input}
            error={error1}
          />
        </div>

        <div style={{ marginBottom: "10px", height: "70px" }}>
          <Input
            placeholder="אימייל"
            value={email}
            setValue={handleChange}
            className={styles.input}
            error={error}
          />
        </div>
        <div style={{ marginBottom: "60px", height: "70px" }}>
          <Input
            placeholder="מספר נייד"
            value={phone}
            setValue={setPhone}
            className={styles.input}
          />
        </div>

        <Button
          className={styles.button}
          onClick={handlebtnClick}
          loading={isLoading1}
        >
          הַבָּא
        </Button>
      </div>
    </div>
  );
};

export default PhoneVerificationRegister;
